table.privacyTable {
    width: 100%;
}
table.privacyTable, th.privacyTable, td.privacyTable {
    border: 1px solid;
    vertical-align: baseline;
}
th.privacyTable {
    background-color: #666666; 
    color: #ffffff; 
    width: 50%; 
}
th.privacyTable, td.privacyTable {
    padding: 10px 10px 10px 10px;
}